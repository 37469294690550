import { Box } from "@mui/material";
import { Helmet } from "react-helmet-async";

import InvitationTable from "./InvitationTable";

import { SITE_TITLE } from "utils/config";
import { useContext, useEffect } from "react";
import { HeaderTextContext } from "../../contexts/HeaderTextContext";

export default function Invitation() {
  const { setPageHeaderText } = useContext(HeaderTextContext);

  useEffect(() => {
    setPageHeaderText("Invitations");
  }, []);
  return (
    <>
      <Helmet>
        <title>{SITE_TITLE} | Invitations</title>
      </Helmet>

      <Box sx={{ height: "calc(100vh - 110px)", backgroundColor: "grey.A200" }}>
        <Box
          className="dx-viewport"
          sx={{ height: "100%" }}
        >
          <InvitationTable />
        </Box>
      </Box>
    </>
  );
}
