import { useEffect, useState } from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Link as MuiLink,
} from "@mui/material";
import moment from "moment";
import { apiGetRelatedQuotes } from "utils/api";
import { Link } from "react-router-dom";

interface Props {
  projectAddress?: string;
  id?: string;
}

export default function RelatedQuotes({ id, projectAddress }: Props) {
  const [quotes, setQuotes] = useState<QuoteType[]>([]);

  useEffect(() => {
    const getRelatedQuotes = async () => {
      if (projectAddress) {
        const res = await apiGetRelatedQuotes({ id, projectAddress });
        setQuotes(res.data.quotes);
      }
    };

    getRelatedQuotes();
  }, [projectAddress]);

  return (
    <Paper sx={{ flex: "1 1 0px", p: 2 }}>
      <Box
        sx={{
          pb: 0.5,
          mb: 2,
          borderBottomWidth: "1px",
          borderBottomStyle: "solid",
          borderBottomColor: "primary.main",
          color: "primary.main",
          fontWeight: 700,
        }}
      >
        Related Quotes
      </Box>
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 600 }}>Quote #</TableCell>
              <TableCell sx={{ fontWeight: 600 }}>Project Name</TableCell>
              <TableCell sx={{ fontWeight: 600 }}>Company</TableCell>
              <TableCell sx={{ fontWeight: 600 }}>Creation Date</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {!quotes.length && (
              <TableRow>
                <TableCell
                  colSpan={4}
                  sx={{
                    color: "rgba(0, 0, 0, 0.6)",
                    fontStyle: "italic",
                    textAlign: "center",
                  }}
                >
                  No related quotes found!
                </TableCell>
              </TableRow>
            )}

            {!!quotes.length &&
              quotes.map((f: QuoteType) => (
                <TableRow key={f.id}>
                  <TableCell>
                    {f.type === "Visit" ? (
                      <MuiLink
                        component={Link}
                        to={`/visit/detail/${f.id as string}`}
                        underline="hover"
                        color="primary"
                        sx={{ fontWeight: 600 }}
                      >
                        {f.quoteId}
                      </MuiLink>
                    ) : (
                      <MuiLink
                        component={Link}
                        to={`/quote/detail/${f.id as string}`}
                        underline="hover"
                        color="primary"
                        sx={{ fontWeight: 600 }}
                      >
                        {f.quoteId}
                      </MuiLink>
                    )}
                  </TableCell>
                  <TableCell>{f.projectName}</TableCell>
                  <TableCell>{f.customerName}</TableCell>
                  <TableCell>
                    {moment(f.creationDate).format("DD/MM/YYYY")}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
