import { Box } from "@mui/material";
import { Helmet } from "react-helmet-async";

import { SITE_TITLE } from "utils/config";
import Detail from "./Detail";
import { HeaderTextContext } from "../../contexts/HeaderTextContext";
import { useContext, useEffect } from "react";
export default function Customers() {
  const { setPageHeaderText } = useContext(HeaderTextContext);

  useEffect(() => {
    setPageHeaderText("Customer Query Filters");
  }, []);
  return (
    <>
      <Helmet>
        <title>{SITE_TITLE} | Customers</title>
      </Helmet>

      <Box sx={{ height: "calc(100vh - 110px)", backgroundColor: "grey.A200" }}>
        <Box sx={{ height: "100%", p: 1 }}>
          <Detail />
        </Box>
      </Box>
    </>
  );
}
