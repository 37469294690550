import { Box } from "@mui/material";
import { Helmet } from "react-helmet-async";

import UserTable from "./UserTable";

import { SITE_TITLE } from "utils/config";
import { useContext, useEffect } from "react";
import { HeaderTextContext } from "../../contexts/HeaderTextContext";

export default function User() {
  const { setPageHeaderText } = useContext(HeaderTextContext);

  useEffect(() => {
    setPageHeaderText("Users");
  }, []);

  return (
    <>
      <Helmet>
        <title>{SITE_TITLE} | Users</title>
      </Helmet>

      <Box sx={{ height: "calc(100vh - 200px)", backgroundColor: "grey.A200" }}>
        <Box className="dx-viewport" sx={{ height: "calc(100vh - 110px)" }}>
          <UserTable />
        </Box>
      </Box>
    </>
  );
}
