import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import axios, { AxiosResponse } from 'axios';
import { embedDashboard } from '@superset-ui/embedded-sdk';
import { getGuestAccessToken } from '../../utils/api';
import { CircularLoading } from '../../components';
import { enqueueSnackbar } from 'notistack';
import { Height } from '@mui/icons-material';

interface GuestTokenResponse {
    success: boolean;
    token: string;
    dashboardId: string;
    baseUrl: string;
}

const MadReport: React.FC = () => {
    const [guestToken, setGuestToken] = useState<string | null>(null);
    const [dashboardId, setDashboardId] = useState<string | null>(null);
    const [baseUrl, setBaseUrl] = useState<string | null>(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchGuestToken = async () => {
            try {
                const response: AxiosResponse<GuestTokenResponse> = await getGuestAccessToken();
                if (response.data.success) {
                    setGuestToken(response.data.token);
                    setDashboardId(response.data.dashboardId);
                    setBaseUrl(response.data.baseUrl);

                } else {
                    throw new Error('Failed to fetch guest token');
                }
            } catch (error) {
                console.error('Error fetching guest token:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchGuestToken();
    }, []);

    useEffect(() => {
        if (guestToken && dashboardId && baseUrl) {
            embedDashboard({
                id: dashboardId,
                supersetDomain: baseUrl,
                mountPoint: document.getElementById('superset-embed-container') as HTMLElement,
                fetchGuestToken: () => Promise.resolve(guestToken),
                dashboardUiConfig: {
                    hideTitle: true,
                    filters: {
                        expanded: true
                    }
                }
            });
        }
    }, [guestToken, dashboardId]);

    if (loading) {
        return <CircularLoading />;
    }

    if (!guestToken || !dashboardId) {
        enqueueSnackbar('Failed to Load Dashboard', { variant: 'error' });
        return <div>Error: Failed to Load Dashboard</div>;
    }

    return (
        <Box sx={{ background: "white", height: "100%", width: "100%" }}>
            <div id="superset-embed-container" className='embedded-superset' style={{ height: '100%', width: '100%' }} />
        </Box>
    );
};

export default MadReport;
