import { useContext, useEffect, useState } from "react";

import {
  Autocomplete,
  Box,
  Button,
  Grid,
  Paper,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars-2";
import { Helmet } from "react-helmet-async";
import { Controller, useForm } from "react-hook-form";

import { apiCreateQuoteVisit, apiInitializeQuoteVisit } from "utils/api";
import { SITE_TITLE } from "utils/config";
import EmoticonRating from "components/shared/EmoticonRating";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { HeaderTextContext } from "../../../contexts/HeaderTextContext";

export default function QuoteVisitCreate() {
  const [customerNames, setCustomerNames] = useState<any[]>([]);
  const [salesPerson, setSalesPerson] = useState<string>("");
  const [isSending, setIsSending] = useState<boolean>(false);
  const auth = useSelector((state: RootState) => state.auth);
  const { setPageHeaderText } = useContext(HeaderTextContext);
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = useForm();

  const onSubmit = async (values: any) => {
    setIsSending(true);

    await apiCreateQuoteVisit({
      ...values,
      salesPerson,
    })
      .then((res) => {
        if (res.status === 200) {
          enqueueSnackbar(res.data?.message, { variant: "success" });
          navigate("/quote", { replace: true });
        } else {
          enqueueSnackbar(res.data?.message, { variant: "error" });
        }
        setIsSending(false);
      })
      .catch(() => {
        enqueueSnackbar("Something went wrong. Please try again later.", {
          variant: "error",
        });
        setIsSending(false);
      });
  };

  useEffect(() => {
    const initialize = async () => {
      await apiInitializeQuoteVisit()
        .then((res) => {
          if (res.status === 200) {
            setCustomerNames(res.data.distinctCustomerNames);
            reset();
          } else {
            enqueueSnackbar(res.data?.message, { variant: "error" });
          }
        })
        .catch(() => {
          enqueueSnackbar("Something went wrong. Please try again later", {
            variant: "error",
          });
        });
    };

    initialize();

    setPageHeaderText("Quotes | Visit | Create");
  }, []);

  useEffect(() => {
    if (auth) {
      setSalesPerson(auth.user.firstName + " " + auth.user.lastName);
    } else {
      setSalesPerson("");
    }
  }, [auth]);

  const handleBack = () => {
    navigate("/quote");
  };

  return (
    <>
      <Helmet>
        <title>{SITE_TITLE} | Quotes | Visit | Create</title>
      </Helmet>

      <Box sx={{ height: 1, p: 1, backgroundColor: "grey.A200" }}>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          sx={{ height: "100%" }}
        >
          <Scrollbars universal>
            <Paper sx={{ p: 2 }}>
              <Grid
                container
                spacing={2}
                direction={{ xs: "column", sm: "row" }}
              >

                <Grid item sm={6}>
                  <Controller
                    control={control}
                    name="salesPerson"
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        disabled
                        size="small"
                        variant="outlined"
                        label="Sales Person"
                        {...field}
                        value={salesPerson}
                      />
                    )}
                  />
                </Grid>

                <Grid item sm={3}>
                  <Controller
                    control={control}
                    name="creationDate"
                    defaultValue={dayjs()}
                    render={({ field }) => (
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          format="DD/MM/YYYY"
                          slotProps={{
                            textField: {
                              size: "small",
                              variant: "outlined",
                            },
                            field: {
                              shouldRespectLeadingZeros: true,
                            },
                          }}
                          label="Date"
                          {...field}
                        />
                      </LocalizationProvider>
                    )}
                  />
                </Grid>

                <Grid item sm={12}>
                  <Controller
                    control={control}
                    name="customerName"
                    render={({ field }) => (
                      <Autocomplete
                        freeSolo
                        disablePortal
                        size="small"
                        options={customerNames}
                        getOptionLabel={(option) =>
                          option.customerName ?? option
                        }
                        isOptionEqualToValue={(option, value) => {
                          return option.customerName === value;
                        }}
                        renderInput={(params) => (
                          <TextField
                            label="Customer"
                            variant="outlined"
                            {...params}
                          />
                        )}
                        {...field}
                        value={field.value ?? null}
                        onChange={(event, value) => {
                          field.onChange(value?.customerName ?? "");
                        }}
                        onInputChange={(event, value, reason) => {
                          if (reason === "input" && value) {
                            field.onChange(value);
                          }
                        }}
                      />
                    )}
                  />
                </Grid>

                <Grid item sm={12}>
                  <Controller
                    control={control}
                    name="attendees"
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        size="small"
                        variant="outlined"
                        label="Attendees"
                        {...field}
                        value={field.value ?? ""}
                      />
                    )}
                  />
                </Grid>

                <Grid item sm={6}>
                  <Controller
                    control={control}
                    name="location"
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        size="small"
                        variant="outlined"
                        label="Location"
                        {...field}
                        value={field.value ?? ""}
                      />
                    )}
                  />
                </Grid>

                <Grid item sm={6} alignSelf="center" justifySelf="center">
                  <Controller
                    control={control}
                    name="reception"
                    defaultValue={4}
                    render={({ field }) => <EmoticonRating {...field} />}
                  />
                </Grid>

                <Grid item sm={12}>
                  <Controller
                    control={control}
                    name="summaryOfMeeting"
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        size="small"
                        variant="outlined"
                        multiline
                        rows={4}
                        label="Summary of Meeting"
                        {...field}
                        value={field.value ?? ""}
                      />
                    )}
                  />
                </Grid>

                <Grid item sm={6}>
                  <Controller
                    control={control}
                    name="doingRight"
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        size="small"
                        variant="outlined"
                        multiline
                        rows={3}
                        label="What we are doing right"
                        {...field}
                        value={field.value ?? ""}
                      />
                    )}
                  />
                </Grid>

                <Grid item sm={6}>
                  <Controller
                    control={control}
                    name="areasToImprove"
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        size="small"
                        variant="outlined"
                        multiline
                        rows={3}
                        label="Areas to Improve"
                        {...field}
                        value={field.value ?? ""}
                      />
                    )}
                  />
                </Grid>

                <Grid item sm={12}>
                  <Controller
                    control={control}
                    name="actionNextSteps"
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        size="small"
                        variant="outlined"
                        multiline
                        rows={4}
                        label="Actions/Next Steps"
                        {...field}
                        value={field.value ?? ""}
                      />
                    )}
                  />
                </Grid>

                <Grid item sm={12}>
                  <Box
                    sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}
                  >
                    <Button
                      variant="outlined"
                      size="large"
                      onClick={handleBack}
                    >
                      Back
                    </Button>

                    <LoadingButton
                      loading={isSubmitting || isSending}
                      type="submit"
                      variant="contained"
                    >
                      Save
                    </LoadingButton>
                  </Box>
                </Grid>
              </Grid>
            </Paper>
          </Scrollbars>
        </Box>
      </Box>
    </>
  );
}
