const roles = {
  admin: "admin",
  mad: "mad",
  ecr: "ecr",
  gal: "gal",
  vantage: "vantage",
  lift_solutions: "lift solutions",
};

export default roles;
