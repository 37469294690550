import { Box } from "@mui/material";
import { Helmet } from "react-helmet-async";

import { SITE_TITLE } from "utils/config";
import { HeaderTextContext } from "../../contexts/HeaderTextContext";
import { useContext, useEffect } from "react";
import MadReport from "./madreport";


export default function Dashboard() {
    const { setPageHeaderText } = useContext(HeaderTextContext);

    useEffect(() => {
        setPageHeaderText("Sales Dashboard");
    }, []);
    return (
        <>
            <Helmet>
                <title>{SITE_TITLE} | Dashboard</title>
            </Helmet>
            <Box sx={{ height: "calc(100vh - 110px)", backgroundColor: "grey.A200" }}>
                <Box sx={{ height: "100%", p: 1 }}>
                    <MadReport />
                </Box>
            </Box>
        </>
    );
}
