import React, { useRef, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { Drawer, Box, Button, Typography, IconButton } from '@mui/material';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import "react-pdf/dist/esm/Page/TextLayer.css";
// import CloseIcon from '@mui/icons-material/Close';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

interface PdfViewerComponentProps {
    file: string | ArrayBuffer | Blob;
}

const PdfViewerComponent: React.FC<PdfViewerComponentProps> = ({ file }) => {
    const [open, setOpen] = useState(true);
    const [numPages, setNumPages] = useState<number | null>(null);
    const [currentPage, setCurrentPage] = useState(1);
    const scale = 1.2
    const drawerRef = useRef<HTMLDivElement>(null);


    const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
        setNumPages(numPages);
    };

    const goToNextPage = () => {
        setCurrentPage(prevPage => prevPage < (numPages ?? 0) ? prevPage + 1 : prevPage);
    };

    const goToPreviousPage = () => {
        setCurrentPage(prevPage => prevPage > 1 ? prevPage - 1 : prevPage);
    };
    const handleCloseDrawer = () => {
        setOpen(false);
    };
    return (
        <div style={{ display: 'flex', width: '100%' }} ref={drawerRef}>
            <Drawer
                anchor="right"
                open={open}
                onClose={handleCloseDrawer}
                variant="persistent"
                slotProps={{
                    backdrop: {
                        onClick: handleCloseDrawer
                    }
                }}
                sx={{ width: 400, flexShrink: 0, '.pdf-document': { width: '100%' } }}
            >
                {/* <IconButton
                    sx={{ position: 'absolute', top: '8px', right: '8px', zIndex: 1 }}
                    onClick={handleCloseDrawer}
                >
                <CloseIcon />
            </IconButton> */}
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 2 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 2 }}>
                        <Button disabled={currentPage === 1} onClick={goToPreviousPage}>
                            Previous
                        </Button>
                        <Typography sx={{ marginX: 2 }}>
                            Page {currentPage} of {numPages}
                        </Typography>
                        <Button disabled={currentPage === numPages} onClick={goToNextPage}>
                            Next
                        </Button>
                    </Box>
                </Box>
                <Box sx={{ overflowY: 'auto', height: 'calc(100vh - 100px)', padding: '0 10px' }}>
                    <Document file={file} onLoadSuccess={onDocumentLoadSuccess} className="pdf-document">
                        <Page pageNumber={currentPage} scale={scale} />
                    </Document>
                </Box>
            </Drawer>
        </div >
    );
};

export default PdfViewerComponent;