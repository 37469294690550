import { DeleteOutline } from "@mui/icons-material";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  IconButton,
  SelectChangeEvent,
} from "@mui/material";

interface Props {
  viewProfiles: ViewProfileType[];
  currentProfile: ViewProfileType;
  setCurrentProfile: (currentProfile: ViewProfileType) => void;
  setProfileNameToDelete: (profileName: string) => void;
  setShowDeleteProfileModalDialog: (
    showDeleteProfileModalDialog: boolean
  ) => void;
}

export default function ViewProfile(props: Props) {
  const {
    viewProfiles,
    currentProfile,
    setCurrentProfile,
    setProfileNameToDelete,
    setShowDeleteProfileModalDialog,
  } = props;

  const handleProfileChange = (
    event: SelectChangeEvent<string>,
    _child: React.ReactNode
  ) => {
    const profile = viewProfiles.find(
      (profile) => profile.profileName === event.target.value
    );
    if (profile) setCurrentProfile(profile);
    else
      setCurrentProfile({
        ...currentProfile,
        profileName: "Reset Layout",
        profileJson: "",
      });
  };

  const handleProfileDelete = (profileName: string) => (event: React.MouseEvent) => {
    event.stopPropagation();
    setProfileNameToDelete(profileName);
    setShowDeleteProfileModalDialog(true);
  };

  return (
    <>
      <Box display={"inline-block"} component="div">
        <FormControl sx={{ minWidth: 200 }} size="small">
          <InputLabel id="profile-select-label" sx={{ lineHeight: "1.25em" }}>
            Profile
          </InputLabel>
          <Select
            labelId="profile-select-label"
            id="profile"
            value={currentProfile.profileName ?? ""}
            label="Profile"
            onChange={handleProfileChange}
            placeholder="Select Profile"
            sx={{ height: "36px" }}
            renderValue={(value) => value}
          >
            <MenuItem
              className="w-100 view-profile-select-option"
              value="Reset Layout"
              key="reset"
            >
              <Box sx={{ width: "100%" }}>
                <span>Reset Layout</span>
              </Box>
            </MenuItem>
            {viewProfiles &&
              viewProfiles.map((profile) => (
                <MenuItem
                  className="w-100 view-profile-select-option"
                  value={profile.profileName}
                  key={profile.profileName}
                >
                  <Box sx={{ width: "100%" }}>
                    <span>{profile.profileName}</span>
                    <IconButton
                      sx={{ padding: 0, float: "right" }}
                      color="error"
                      onClick={handleProfileDelete(profile.profileName)}
                    >
                      <DeleteOutline />
                    </IconButton>
                  </Box>
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Box>
    </>
  );
}
