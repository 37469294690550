import { useEffect, useState } from "react";
import {
    Autocomplete,
    Box,
    Button,
    Chip,
    Container,
    Grid,
    Paper,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { apiGetAllCustomerTags, apiUpdateCustomerTag } from "utils/api";

interface CustomerTag {
    id: number;
    customerName: string;
    tags: string | null; // tags can be null
}

interface TagsState {
    [key: string]: string[];
}

export default function Detail() {
    const [customerTags, setCustomerTags] = useState<CustomerTag[]>([]);
    const [selectedTags, setSelectedTags] = useState<TagsState>({});
    const [isChanged, setIsChanged] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await apiGetAllCustomerTags();
                setCustomerTags(response.data);
                const initialTags = response.data.reduce((acc: TagsState, { customerName, tags }: CustomerTag) => {
                    acc[customerName] = tags ? tags.split(',').map((tag: string) => tag.trim()) : [];
                    return acc;
                }, {});
                setSelectedTags(initialTags);
            } catch (error) {
                console.error("Error fetching customer tags:", error);
            }
        };

        fetchData();
    }, []);

    const handleTagChange = (customerName: string, value: string[]) => {
        const lowercaseTags = value.map((tag: string) => tag.toLowerCase());
        setSelectedTags({ ...selectedTags, [customerName]: lowercaseTags });
        setIsChanged(true);
    };

    const handleSave = async () => {
        try {
            for (const [customerName, tags] of Object.entries(selectedTags)) {
                // Find the corresponding customer ID
                const customer = customerTags.find(c => c.customerName === customerName);
                if (customer) {
                    await apiUpdateCustomerTag({ id: customer.id, customerName, tags });
                }
            }
            console.log("Tags updated:", selectedTags);
            setIsChanged(false);
        } catch (error) {
            console.error("Error updating customer tags:", error);
        }
    };

    return (
        <Paper sx={{ p: 1, display: "flex", justifyContent: "center" }} component="form">
                <Grid container spacing={2} direction={{ xs: "column", sm: "row" }} mb={1}>
                    {customerTags.map(({ customerName, id }) => (
                        <Grid item md={6} xs={12} key={id}>
                            <Stack spacing={1.5}>
                                <Typography variant="h6">{customerName}</Typography>
                                <Autocomplete
                                    multiple
                                    freeSolo
                                    size="small"
                                    options={[]}
                                    value={selectedTags[customerName] || []}
                                    onChange={(event, value) => handleTagChange(customerName, value)}
                                    renderInput={(params) => <TextField {...params} label="Tags" />}
                                    renderTags={(value, getTagProps) =>
                                        value.map((option, index) => (
                                            <Chip
                                                {...getTagProps({ index })}
                                                color="primary"
                                                size="small"
                                                label={option}
                                            />
                                        ))
                                    }
                                />
                            </Stack>
                        </Grid>
                    ))}
                    <Box
                        sx={{
                            width: "100%",
                            px: 2,
                            display: "flex",
                            justifyContent: "flex-end",
                            gap: 2,
                            mt: 2,
                        }}
                    >
                        {isChanged && (
                            <Button variant="contained" size="large" onClick={handleSave}>
                                Save
                            </Button>
                        )}
                    </Box>
                </Grid>
        </Paper>
    );
}
