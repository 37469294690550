import {
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
} from "@mui/material";

interface Props {
  showDeleteProfileModalDialog: boolean;
  setShowDeleteProfileModalDialog: (
    showDeleteProfileModalDialog: boolean
  ) => void;
  handleDeleteProfile: () => void;
  profileNameToDelete: string;
}

export default function DeleteProfileConfirmDialog(props: Props) {
  const {
    showDeleteProfileModalDialog,
    setShowDeleteProfileModalDialog,
    handleDeleteProfile,
    profileNameToDelete,
  } = props;

  return (
    <Dialog
      open={showDeleteProfileModalDialog}
      onClose={() => {
        setShowDeleteProfileModalDialog(false);
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Want to delete profile "{profileNameToDelete}"?
      </DialogTitle>
      <DialogActions>
        <Button
          onClick={() => {
            setShowDeleteProfileModalDialog(false);
          }}
        >
          Cancel
        </Button>
        <Button onClick={handleDeleteProfile} autoFocus>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}
