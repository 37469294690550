import { useState } from "react";

import {
  Box,
  ButtonBase,
  Drawer,
  IconButton,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { MdLogout } from "react-icons/md";
import { Outlet, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { Scrollbars } from "react-custom-scrollbars-2";
import { enqueueSnackbar } from "notistack";
import Logo from "./Logo";
import { MenuItem } from "./MenuItem";
import { menus } from "./menus";
import User from "./User";
import { RootState } from "store";
import { setAuth } from "store/auth";
import { apiLogout } from "utils/api";
import MenuIcon from "@mui/icons-material/Menu";
import { AppBar } from "./AppBar";
import { HeaderTextContext } from "../../contexts/HeaderTextContext";

const drawerWidth = 200;

export default function Layout() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const auth = useSelector((state: RootState) => state.auth);

  const theme = useTheme();
  const isMobile = !useMediaQuery(theme.breakpoints.up("md"));
  const [openDrawer, setOpenDrawer] = useState(false);
  const [pageHeaderText, setPageHeaderText] = useState('quo');

  const role = auth?.user?.role;

  const handleSignOut = async () => {
    await apiLogout()
      .then((res) => {
        if (res.status === 200) {
          dispatch(setAuth({ initialized: false, user: null }));
          navigate("/login", { replace: true });
        }
      })
      .catch(() => {
        enqueueSnackbar("Something went wrong. Please try again later", {
          variant: "error",
        });
      });
  };

  const handleDrawerOpen = () => {
    setOpenDrawer(true);
  };

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };

  return (
    <Box sx={{ height: "100vh" }}>
      <Box sx={{ width: 1, height: 1, display: "flex" }}>
        <AppBar
          position="fixed"
          open={openDrawer}
          width={drawerWidth}
          color="inherit"
          sx={{
            height: 64,
            px: 2,
            flexDirection: "row",
            alignItems: "center",
            zIndex: 5,
          }}
        >
          <Toolbar
            sx={{
              backgroundColor: "common.white",
              color: "primary.main",
              width: "100%",
              paddingTop: "3px",
              paddingLeft: `${isMobile ? "16px" : "0px"} !important`,
              paddingRight: "0px !important",
            }}
          >
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{ mr: 2, ...(openDrawer && { display: "none" }) }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap component="div" width={`100%`}>
              <Box
                display={"inline-block"}
                sx={{
                  float: "left",
                  color: "black",
                  paddingTop: "10px",
                  fontWeight: "bold"
                }}
              >
                {pageHeaderText}
              </Box>
              <Box
                display={"inline-block"}
                sx={{
                  float: "right",
                }}
              >
                <User onSignOut={handleSignOut} />
              </Box>
            </Typography>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="temporary"
          anchor="left"
          open={openDrawer}
          ModalProps={{ keepMounted: true }}
          PaperProps={{
            elevation: 8,
            sx: {
              backgroundColor: "common.white",
              display: "flex",
              flexDirection: "column",
            },
          }}
          sx={{
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
            },
          }}
          onClose={handleDrawerClose}
        >
          <Scrollbars autoHide universal>
            <Logo />

            {menus
              .filter((m) => _.includes(m.roles, role))
              .map((menu) => (
                <MenuItem
                  key={menu.url}
                  url={menu.url}
                  label={menu.label}
                  icon={menu.icon}
                />
              ))}

            <Box sx={{ pr: 2, mb: 1 }}>
              <ButtonBase
                onClick={handleSignOut}
                sx={{
                  width: 1,
                  height: 40,
                  pl: 3,
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: 3,
                  backgroundColor: "common.white",
                  color: "primary.main",
                  "&:hover": {
                    backgroundColor: "primary.light",
                    color: "common.white",
                  },
                  borderTopRightRadius: 20,
                  borderBottomRightRadius: 20,
                }}
              >
                <MdLogout />
                <Box sx={{ fontSize: 14 }}>Sign out</Box>
              </ButtonBase>
            </Box>
          </Scrollbars>
        </Drawer>
        <Box sx={{ flexGrow: 1, marginTop: "64px" }}>
          <Box sx={{ height: "100%" }}>
            <Scrollbars autoHide universal>
            <HeaderTextContext.Provider value={{setPageHeaderText}}>
              <Outlet />
            </HeaderTextContext.Provider>
            </Scrollbars>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
