import { createRef, useEffect, useState } from "react";
import { Box, Button as MuiButton, Link as MuiLink } from "@mui/material";
import { Button } from "devextreme-react/button";
import {
  Column,
  ColumnChooser,
  ColumnFixing,
  DataGrid,
  FilterPanel,
  FilterRow,
  Item,
  Pager,
  Sorting,
  StateStoring,
  Toolbar,
  DataGridTypes,
  Export,
} from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import { Paging } from "devextreme-react/tree-list";
import { enqueueSnackbar } from "notistack";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import {
  apiCreateViewProfile,
  apiDeleteViewProfile,
  apiGetAllQuotes,
  apiGetAllViewProfiles,
  apiUpdateViewProfile,
} from "utils/api";

import "devextreme/dist/css/dx.light.css";
import roles from "utils/roles";
import { RootState } from "store";
import { Workbook } from "exceljs";
import saveAs from "file-saver";
import { exportDataGrid } from "devextreme/excel_exporter";
import ViewProfile from "./viewprofile/ViewProfile";
import { SaveProfileModal } from "./viewprofile/SaveProfileModal";
import { AxiosResponse } from "axios";
import DeleteProfileConfirmDialog from "./viewprofile/DeleteProfileConfirmDialog";

export default function QuoteTable() {
  const [viewProfiles, setViewProfiles] = useState<ViewProfileType[]>([]);
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [showDeleteProfileModalDialog, setShowDeleteProfileModalDialog] =
    useState(false);
  const [newProfileName, setNewProfileName] = useState("");
  const [profileNameToDelete, setProfileNameToDelete] = useState("");
  const [doesNewProfileExists, setDoesNewProfileExists] = useState(false);
  const [currentProfile, setCurrentProfile] = useState<ViewProfileType>({
    userId: 0,
    profileName: "",
    profileJson: "",
  });
  const [dataSource, setDataSource] = useState<CustomStore>();
  const dataGridRef = createRef<DataGrid>();
  const auth = useSelector((state: RootState) => state.auth.user);

  useEffect(() => {
    const initialize = async () => {
      await getAllViewProfiles();
      getAllQuotes();
    };

    initialize();
  }, []);

  useEffect(() => {
    if (currentProfile && currentProfile.profileName === "Reset Layout") {
      localStorage.removeItem("mad-quote-profile");
      dataGridRef.current?.instance.state(null);
    } else if (currentProfile && currentProfile.profileJson) {
      changeGridState();
    } else {
      setInitialProfile();
    }
  }, [currentProfile]);

  useEffect(() => {
    if (!currentProfile?.profileJson) {
      setInitialProfile();
    }
  }, [viewProfiles]);

  useEffect(() => {
    if (newProfileName && viewProfiles.length > 0) {
      const exists = viewProfiles.some(
        (p) =>
          p.profileName.toLowerCase().trim() ===
          newProfileName.toLowerCase().trim()
      );

      setDoesNewProfileExists(exists);
    } else {
      setDoesNewProfileExists(false);
    }
  }, [newProfileName]);

  useEffect(() => {
    setNewProfileName("");
    setDoesNewProfileExists(false);
  }, [showProfileModal]);

  const setInitialProfile = () => {
    const previousProfile = localStorage.getItem("mad-quote-profile");
    const viewProfile = viewProfiles.find(
      (p) => p.profileName === previousProfile
    );
    if (viewProfile) {
      setCurrentProfile(viewProfile);
    }
  };

  const changeGridState = () => {
    localStorage.setItem(
      "mad-quote-list",
      JSON.stringify(currentProfile.profileJson)
    );
    localStorage.setItem("mad-quote-profile", currentProfile.profileName);
    dataGridRef.current?.instance.state(currentProfile.profileJson);
  };

  const getAllQuotes = () => {
    const dtSource = new CustomStore({
      key: "id",
      load: (loadOptions) => {
        return apiGetAllQuotes({ loadOptions })
          .then((res) => {
            return {
              data: res.data.data,
              totalCount: res.data.totalCount,
            };
          })
          .catch(() => {
            enqueueSnackbar("Something went wrong. Please try again later", {
              variant: "error",
            });
            return;
          });
      },
    });

    setDataSource(dtSource);
  };

  const getAllViewProfiles = async () => {
    await apiGetAllViewProfiles({ userId: auth.id }).then(async (res) => {
      setViewProfiles(res.data.userViewProfiles);
    });
  };

  const validateProfileName = () => {
    if (!newProfileName) {
      enqueueSnackbar("Please enter a profile name", {
        variant: "error",
      });
      return false;
    }

    if (newProfileName.trim().toLowerCase() === "reset layout") {
      enqueueSnackbar(`Profile name cannot be "${newProfileName}"`, {
        variant: "error",
      });
      return false;
    }

    return true;
  };

  const handleUserProfileResponse = async (
    userProfileResponse: Promise<AxiosResponse<any, any>>,
    isDelete: boolean = false
  ) => {
    await userProfileResponse
      .then(async (res) => {
        enqueueSnackbar(res.data.message, {
          variant: "success",
        });

        if (isDelete) {
          if (currentProfile.profileName === profileNameToDelete) {
            setCurrentProfile({
              userId: 0,
              profileName: "",
              profileJson: "",
            });
            localStorage.removeItem("mad-quote-profile");
            dataGridRef.current?.instance.state(null);
          }
        } else {
          const profile = res.data.viewProfiles.find(
            (p: ViewProfileType) => p.profileName === newProfileName
          );

          setCurrentProfile(profile);
        }

        setViewProfiles(res.data.viewProfiles);
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar("Something went wrong. Please try again later", {
          variant: "error",
        });
      })
      .finally(() => {
        setShowProfileModal(false);
        setNewProfileName("");
      });
  };

  const saveUserViewProfile = async () => {
    if (validateProfileName()) {
      const quoteListProfile = localStorage.getItem("mad-quote-list");

      if (doesNewProfileExists) {
        await handleUserProfileResponse(
          apiUpdateViewProfile({
            userId: auth.id,
            profileName: newProfileName,
            profileJson: quoteListProfile,
          })
        );
      } else {
        await handleUserProfileResponse(
          apiCreateViewProfile({
            userId: auth.id,
            profileName: newProfileName,
            profileJson: quoteListProfile,
          })
        );
      }
    }
  };

  const handleDeleteProfile = async () => {
    await handleUserProfileResponse(
      apiDeleteViewProfile({
        userId: auth.id,
        profileName: profileNameToDelete,
      }),
      true
    ).then(() => {
      setShowDeleteProfileModalDialog(false);
      setProfileNameToDelete("");
    });
  };

  const handleRefreshDataGrid = () => {
    dataGridRef?.current?.instance.state(null);
  };

  const cellQuoteIdRender = (data: any) => {
    const row = data.data;

    if (row.type === "Visit")
      return (
        <MuiLink
          component={Link}
          to={`visit/detail/${row.id as string}`}
          underline="hover"
          color="primary"
          sx={{ fontWeight: 600 }}
        >
          {data.value}
        </MuiLink>
      );

    return (
      <MuiLink
        component={Link}
        to={`detail/${row.id as string}`}
        underline="hover"
        color="primary"
        sx={{ fontWeight: 600 }}
      >
        {data.value}
      </MuiLink>
    );
  };

  const cellSalesPersonRender = (data: any) => {
    const row = data.data;
    if (row.type === "Visit") {
      return <>{row.salesPerson}</>;
    } else {
      if (row.state && row.state.users) {
        return (
          <>
            {row.state.users.map((user: UserType, index: number) => (
              <span key={index}>
                {index !== 0 && <Box component="span">, </Box>}
                {auth.role == roles.admin ? (
                  <Box component="span">{user.fullName || "unnamed"}</Box>
                ) : (
                  <Box component="span">{user.fullName}</Box>
                )}
              </span>
            ))}
          </>
        );
      } else {
        return <></>;
      }
    }
  };

  const cellCommonRender = (data: any) => {
    const row = data.data;

    if (row.type === "Visit")
      return <Box sx={{ backgroundColor: "#7f7f7f" }}>&nbsp;</Box>;

    return <Box component="div">{data.text}</Box>;
  };

  const onExporting = (e: DataGridTypes.ExportingEvent) => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet("Quotes");
    if (dataGridRef.current) {
      exportDataGrid({
        component: e.component,
        worksheet: worksheet,
        autoFilterEnabled: true,
        customizeCell: ({ gridCell, excelCell }) => {
          if (gridCell?.rowType === "data") {
            if (
              gridCell?.column &&
              gridCell.column.caption === "Sales Person"
            ) {
              if (gridCell.data?.type === "Visit") {
                excelCell.value = gridCell.data?.salesPerson;
              } else {
                if (gridCell.data?.state && gridCell.data?.state.users) {
                  const users = gridCell.data.state?.users;
                  const salesPersons = users
                    ? users
                        .map(
                          (user: UserType, index: number) =>
                            `${index !== 0 ? ", " : ""}${
                              user.fullName || "unnamed"
                            }`
                        )
                        .join("")
                    : "";
                  excelCell.value = salesPersons;
                } else {
                  excelCell.value = "";
                }
              }
            }
          }
        },
      }).then(() => {
        const timestamp = new Date()
          .toLocaleString("en-US", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            hour12: true,
          })
          .replace(/\//g, "-")
          .replace(/, /g, "_")
          .replace(/:/g, "-");
        workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(
            new Blob([buffer], { type: "application/octet-stream" }),
            `salesportal_${timestamp}.xlsx`
          );
        });
      });
      e.cancel = true;
    } else {
      console.error("DataGrid instance is not available.");
    }
  };

  return (
    <>
      <SaveProfileModal
        showProfileModal={showProfileModal}
        setShowProfileModal={setShowProfileModal}
        newProfileName={newProfileName}
        setNewProfileName={setNewProfileName}
        saveUserViewProfile={saveUserViewProfile}
        doesNewProfileExists={doesNewProfileExists}
      />
      <DeleteProfileConfirmDialog
        showDeleteProfileModalDialog={showDeleteProfileModalDialog}
        setShowDeleteProfileModalDialog={setShowDeleteProfileModalDialog}
        handleDeleteProfile={handleDeleteProfile}
        profileNameToDelete={profileNameToDelete}
      />
      <Box sx={{ background: "white", height: "100%", p: 1 }}>
        <DataGrid
          ref={dataGridRef}
          dataSource={dataSource}
          width="100%"
          height="100%"
          showBorders={true}
          remoteOperations={true}
          wordWrapEnabled={false}
          columnAutoWidth={true}
          rowAlternationEnabled={true}
          hoverStateEnabled={true}
          onExporting={onExporting}
          className="quotes-datagrid"
        >
          <Export enabled={true} />
          <FilterPanel visible={true} />
          <Sorting mode="multiple" />
          <FilterRow visible={true} />
          <StateStoring
            enabled={true}
            type="localStorage"
            storageKey="mad-quote-list"
          />

          <Paging defaultPageSize={10} />
          <Pager
            visible={true}
            allowedPageSizes={[5, 10, 15, 20, 25, 30, 50, 100]}
            displayMode="adaptive"
            showPageSizeSelector={true}
            showInfo={true}
            showNavigationButtons={true}
          />
          <ColumnChooser enabled={true} />
          <ColumnFixing enabled={true} />
          <Toolbar>
            <Item location="before">
              <Box display={"inline-block"} component="div">
                {["admin", "ecr", "mad"].includes(auth.role) && (
                  <MuiButton
                    component={Link}
                    to="/quote/visit/create"
                    variant="contained"
                  >
                    Add New Visit Notes
                  </MuiButton>
                )}
              </Box>
            </Item>
            <Item location="after">
              <Box
                display={"inline-block"}
                component="div"
                className="datagrid-custom-buttons"
              >
                <ViewProfile
                  viewProfiles={viewProfiles}
                  currentProfile={currentProfile}
                  setCurrentProfile={setCurrentProfile}
                  setProfileNameToDelete={setProfileNameToDelete}
                  setShowDeleteProfileModalDialog={
                    setShowDeleteProfileModalDialog
                  }
                />
                <Button
                  icon="save"
                  hint="Add/Update Profile"
                  className="vertical-top"
                  onClick={() => setShowProfileModal(true)}
                />
              </Box>
            </Item>
            <Item name="columnChooserButton" />
            <Item name="exportButton" />
          </Toolbar>
          <Column dataField="type" caption="Type" fixed={true} minWidth={60} />
          <Column
            dataField="quoteId"
            caption="Quote Id"
            fixed={true}
            minWidth={80}
            cellRender={cellQuoteIdRender}
          />
          <Column
            dataField="status"
            caption="Status"
            minWidth={60}
            cellRender={cellCommonRender}
          />
          <Column
            dataField="probability"
            caption="Probability"
            minWidth={60}
            cellRender={cellCommonRender}
            visible={false}
          />
          <Column
            dataField="projectName"
            caption="Project Name"
            minWidth={110}
            cellRender={cellCommonRender}
          />
          <Column
            dataField="units"
            caption="Units"
            dataType="number"
            minWidth={60}
            cellRender={cellCommonRender}
          />
          <Column
            dataField="value"
            caption="Value"
            dataType="number"
            format={{
              type: "currency",
              precision: 2,
            }}
            minWidth={80}
            cellRender={cellCommonRender}
          />
          <Column
            dataField="customerName"
            caption="Customer Name"
            minWidth={120}
          />
          <Column
            dataField="contact"
            caption="Contact"
            minWidth={80}
            cellRender={cellCommonRender}
          />
          <Column
            dataField="endContractor"
            caption="End Contractor"
            minWidth={120}
            cellRender={cellCommonRender}
          />
          <Column
            dataField="projectAddress"
            caption="Project Address"
            minWidth={120}
            cellRender={cellCommonRender}
            visible={false}
          />
          <Column
            dataField="sysAwardDate"
            caption="Sys Award Date"
            dataType="date"
            format="dd/MM/yyyy"
            defaultSortOrder="desc"
            minWidth={120}
            visible={false}
          />
          <Column
            dataField="state.users.fullName"
            caption="Sales Person"
            dataType="string"
            minWidth={160}
            cellRender={cellSalesPersonRender}
          />
          <Column
            dataField="stateCode"
            caption="State"
            dataType="string"
            minWidth={160}
          />
          <Column
            dataField="quoteBy"
            caption="Quote By"
            minWidth={80}
            cellRender={cellCommonRender}
          />
          <Column
            dataField="creationDate"
            caption="Creation Date"
            dataType="date"
            format="dd/MM/yyyy"
            defaultSortOrder="desc"
            minWidth={120}
          />
          <Column
            dataField="revision"
            caption="Revision"
            minWidth={60}
            dataType="number"
            visible={false}
          />
          <Column
            dataField="revisionDate"
            caption="Revision Date"
            dataType="date"
            format="dd/MM/yyyy"
            defaultSortOrder="desc"
            minWidth={120}
            visible={false}
          />
          <Column
            dataField="estAwardDate"
            caption="Est Award Date"
            dataType="date"
            format="dd/MM/yyyy"
            minWidth={120}
            cellRender={cellCommonRender}
          />
          <Column
            dataField="nextFollowUp"
            caption="Next Follow Up"
            dataType="date"
            format="dd/MM/yyyy"
            minWidth={120}
            cellRender={cellCommonRender}
          />
        </DataGrid>
      </Box>
    </>
  );
}
