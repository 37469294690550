type AddressComponent = google.maps.GeocoderAddressComponent;
type GeocodedAddress = {
  formattedAddress: string;
  country: string;
  address: string;
  city: string;
  stateShort: string; // State short name (e.g., "CA")
  stateLong: string; // State long name (e.g., "California")
  zipcode: string;
  latitude: number;
  longitude: number;
};
// Helper function to extract address component
const getAddressComponent = (
  components: AddressComponent[],
  type: string,
  longName = false
) => {
  return (
    components.find((component) => component.types.includes(type))?.[
      longName ? "long_name" : "short_name"
    ] ?? ""
  );
};

const parseGeocodeResult = (result: google.maps.GeocoderResult) => {
  const addressComponents = result.address_components;
  const country = getAddressComponent(addressComponents, "country");
  const zipcode = getAddressComponent(addressComponents, "postal_code");
  const stateShort = getAddressComponent(
    addressComponents,
    "administrative_area_level_1"
  );
  const stateLong = getAddressComponent(
    addressComponents,
    "administrative_area_level_1",
    true
  );

  // Check for 'locality', if not found, use 'sublocality_level_1'
  let city = getAddressComponent(addressComponents, "locality");
  if (!city) {
    city = getAddressComponent(addressComponents, "sublocality_level_1");
  }
  const streetNumber = getAddressComponent(addressComponents, "street_number");
  const route = getAddressComponent(addressComponents, "route");
  const address = [streetNumber, route].filter(Boolean).join(" ");

  // Consider if rounding is necessary for your use case
  const latitude = result.geometry.location.lat();
  const longitude = result.geometry.location.lng();

  return {
    formattedAddress: result.formatted_address,
    country,
    address,
    city,
    stateShort,
    stateLong,
    zipcode,
    latitude,
    longitude,
  };
};

// Use async/await for asynchronous operations
export const getPredictions = async (input: string) => {
  const placeAutocompleteService =
    new window.google.maps.places.AutocompleteService();
  return new Promise((resolve, reject) => {
    placeAutocompleteService.getPlacePredictions(
      {
        input,
        componentRestrictions: { country: ["us", "ca"] },
        // types: ["establishment"], // Changed to focus on landmarks
      },
      (results, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          resolve(results);
        } else {
          console.error(`Error fetching predictions: ${status}`);
          reject(new Error(`Error fetching predictions: ${status}`));
        }
      }
    );
  });
};

export const getRichAddress = async (
  fullAddress: string
): Promise<GeocodedAddress> => {
  const geocoder = new window.google.maps.Geocoder();
  return new Promise((resolve, reject) => {
    geocoder.geocode({ address: fullAddress }, (results, status) => {
      if (status === "OK" && results) {
        const data = parseGeocodeResult(results[0]);
        resolve(data);
      } else {
        console.error(`Error geocoding address: ${status}`);
        reject(new Error(`Error geocoding address: ${fullAddress}`));
      }
    });
  });
};
