// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  box-sizing: border-box;
}

.embedded-superset iframe {
  width: 100%;
  height: 100%;
}

html {
  font-size: 16px;
}

body {
  background-color: #fafafb !important;
}

.dx-row > td {
  vertical-align: middle !important;
}

.custom-smooth-scroll > div:first-child {
  scroll-behavior: smooth;
}

.d-flex {
  display: flex !important;
}

.quotes-datagrid .dx-toolbar-after {
  display: flex !important;
}

.vertical-top {
  vertical-align: top !important;
}

.datagrid-custom-buttons > div {
  margin-left: 5px !important;
}

.w-100 {
  width: 100% !important;
}

.view-profile-select-option {
  padding: 6px 12px;
}

.dx-datagrid-borders > .dx-datagrid-filter-panel {
  position: fixed;
  bottom: 54px;
  background: white;
  width: calc(100% - 16px);
  left: 8px;
  right: 8px;
}

.quotes-datagrid .dx-datagrid-borders > .dx-datagrid-pager,
.users-datagrid .dx-datagrid-borders > .dx-datagrid-pager,
.invitation-datagrid .dx-datagrid-borders > .dx-datagrid-pager,
.state-datagrid .dx-datagrid-borders > .dx-datagrid-pager {
  position: fixed;
  bottom: 0px;
  width: calc(100% - 16px);
  background: white;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;AACxB;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,iCAAiC;AACnC;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,eAAe;EACf,YAAY;EACZ,iBAAiB;EACjB,wBAAwB;EACxB,SAAS;EACT,UAAU;AACZ;;AAEA;;;;EAIE,eAAe;EACf,WAAW;EACX,wBAAwB;EACxB,iBAAiB;AACnB","sourcesContent":["* {\n  box-sizing: border-box;\n}\n\n.embedded-superset iframe {\n  width: 100%;\n  height: 100%;\n}\n\nhtml {\n  font-size: 16px;\n}\n\nbody {\n  background-color: #fafafb !important;\n}\n\n.dx-row > td {\n  vertical-align: middle !important;\n}\n\n.custom-smooth-scroll > div:first-child {\n  scroll-behavior: smooth;\n}\n\n.d-flex {\n  display: flex !important;\n}\n\n.quotes-datagrid .dx-toolbar-after {\n  display: flex !important;\n}\n\n.vertical-top {\n  vertical-align: top !important;\n}\n\n.datagrid-custom-buttons > div {\n  margin-left: 5px !important;\n}\n\n.w-100 {\n  width: 100% !important;\n}\n\n.view-profile-select-option {\n  padding: 6px 12px;\n}\n\n.dx-datagrid-borders > .dx-datagrid-filter-panel {\n  position: fixed;\n  bottom: 54px;\n  background: white;\n  width: calc(100% - 16px);\n  left: 8px;\n  right: 8px;\n}\n\n.quotes-datagrid .dx-datagrid-borders > .dx-datagrid-pager,\n.users-datagrid .dx-datagrid-borders > .dx-datagrid-pager,\n.invitation-datagrid .dx-datagrid-borders > .dx-datagrid-pager,\n.state-datagrid .dx-datagrid-borders > .dx-datagrid-pager {\n  position: fixed;\n  bottom: 0px;\n  width: calc(100% - 16px);\n  background: white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
