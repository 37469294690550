import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";

interface Props {
  showProfileModal: boolean;
  setShowProfileModal: (showProfileModal: boolean) => void;
  newProfileName: string;
  setNewProfileName: (newProfileName: string) => void;
  saveUserViewProfile: () => void;
  doesNewProfileExists: boolean;
}

export function SaveProfileModal(props: Props) {
  const {
    showProfileModal,
    setShowProfileModal,
    newProfileName,
    setNewProfileName,
    saveUserViewProfile,
    doesNewProfileExists,
  } = props;

  const handleDialogClose = () => {
    setShowProfileModal(false);
  };

  return (
    <Box>
      <Dialog
        open={showProfileModal}
        onClose={handleDialogClose}
        PaperProps={{
          component: "form",
          onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            saveUserViewProfile();
          },
          sx: { width: "500px" },
        }}
      >
        <DialogTitle>
          <Box>
            <span style={{ fontSize: "x-large" }}>Add/Update Profile</span>
          </Box>
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            required
            margin="dense"
            name="profileName"
            label="Profile Name"
            type="text"
            fullWidth
            variant="standard"
            value={newProfileName}
            autoComplete="off"
            onChange={(e) => setNewProfileName(e.target.value)}
          />
          {doesNewProfileExists && (
            <Typography variant="caption" sx={{ letterSpacing: 0 }}>
              Profile with the same name already exists. Existing changes will
              be overwritten with the new ones.
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
          <Button type="submit">
            {doesNewProfileExists ? "Update" : "Add"}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
