import { Box } from "@mui/material";
import { Helmet } from "react-helmet-async";
import QuoteTable from "./QuoteTable";
import { SITE_TITLE } from "utils/config";
import { useContext, useEffect } from "react";
import { HeaderTextContext } from "../../contexts/HeaderTextContext";

export default function Quote() {
  const { setPageHeaderText } = useContext(HeaderTextContext);

  useEffect(() => {
    setPageHeaderText("Quotes");
  }, []);

  return (
    <>
      <Helmet>
        <title>{SITE_TITLE} | Quotes</title>
      </Helmet>

      <Box sx={{ height: "calc(100vh - 200px)", backgroundColor: "grey.A200" }}>
        <Box className="dx-viewport" sx={{ height: "calc(100vh - 150px)" }}>
          <QuoteTable />
        </Box>
      </Box>
    </>
  );
}
